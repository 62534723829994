import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/UsingSparkLayout.js";
import validationAnatomy from '../../../images/guides/validation-anatomy.png';
import validationAnatomy2x from '../../../images/guides/validation-anatomy@2x.png';
import { SprkHeading } from '@sparkdesignsystem/spark-react';
export const validationAnatomySet = `${validationAnatomy} 560w, ${validationAnatomy2x} 1120w`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  validationAnatomySet,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <span id="validation-messaging-guide" className="docs-b-Link--anchor"></span>
    <SprkHeading element="h1" additionalClasses="sprk-u-mbl sprk-u-Measure" isPageTitle mdxType="SprkHeading">
  Validation Messaging Guide
    </SprkHeading>
    <span id="main-takeaways" className="docs-b-Link--anchor"></span>
    <SprkHeading element="h2" additionalClasses="sprk-u-mbm sprk-u-Measure" variant="displayThree" mdxType="SprkHeading">
  Main Takeaways
    </SprkHeading>
    <p>{`Giving validation feedback is an emotional process. Support users with this process by following these guidelines:`}</p>
    <ul>
      <li parentName="ul">{`Explain what the Input expects before the user enters data. (Example: password rules, ranges, etc)`}</li>
      <li parentName="ul">{`Give clear and helpful feedback when encountering a problem.`}</li>
      <li parentName="ul">{`Use a combination of `}<a parentName="li" {...{
          "href": "#placement"
        }}>{`placement`}</a>{`, `}<a parentName="li" {...{
          "href": "#timing"
        }}>{`timing`}</a>{`, and `}<a parentName="li" {...{
          "href": "#language"
        }}>{`language`}</a>{` to guide users through moments of uncertainty.`}</li>
    </ul>
    <img srcSet={validationAnatomySet} className="sprk-u-Measure sprk-u-pam sprk-u-pbh sprk-u-Width-100" src={validationAnatomy} alt="Anatomy of Input validation with numbers pointing to each part: placement, timing, and language." />
    <span id="placement" className="docs-b-Link--anchor"></span>
    <SprkHeading element="h2" additionalClasses="sprk-u-mbm sprk-u-Measure" variant="displayThree" mdxType="SprkHeading">
  Placement
    </SprkHeading>
    <ul>
      <li parentName="ul">{`Place messaging close to the Input. Spark places validation directly under the Input.`}</li>
      <li parentName="ul">{`Provide a clear visual to indicate something has gone wrong with user Input and needs attention.`}</li>
      <li parentName="ul">{`Plan error messaging for every field.`}</li>
      <li parentName="ul">{`On forms with multiple fields, show validation messaging for each Input field to guide users to a successful submission.`}</li>
    </ul>
    <span id="timing" className="docs-b-Link--anchor"></span>
    <SprkHeading element="h2" additionalClasses="sprk-u-mbm sprk-u-Measure" variant="displayThree" mdxType="SprkHeading">
  Timing
    </SprkHeading>
    <ul>
      <li parentName="ul">{`Show validation messaging after the user enters the data or when the Input becomes out of `}<strong parentName="li">{`selection focus`}</strong>{`.`}</li>
      <li parentName="ul">{`On forms, show error messaging `}<strong parentName="li">{`before`}</strong>{` the user submits the form.`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Selection focus`}</strong>{` changes when a user taps or clicks anywhere outside of the current focused element. Focus can also change
through keyboard navigation.`}</p>
    </blockquote>
    <span id="language" className="docs-b-Link--anchor"></span>
    <SprkHeading element="h2" additionalClasses="sprk-u-mbm sprk-u-Measure" variant="displayThree" mdxType="SprkHeading">
  Language
    </SprkHeading>
    <ul>
  <li>
    Use labels and helper text to clarify what data the Input expects. Messaging should be related and relevant to the Input.
  </li>
  <li>
    Deliver direct and concise statement of the problem in non-technical terms. Write out abbreviations.
  </li>
  <li>
    Use sentence case. Do not use all caps.
  </li>
  <li>
    Direct users to the next action to solve the problem.
    <ul className="sprk-b-List sprk-b-List--indented sprk-u-Measure sprk-u-mvs">
      <li>
        Use positive action verbs, rather than negative ones. Focus on the solution by explaining what to do, opposed to what not to do.
      </li>
      <li>
        If the “error” is a system condition that blocks the user from accomplishing a task, offer them a way to be notified of updated system status.
      </li>
    </ul>
  </li>
  <li>
    Follow accessibility guidelines, and where applicable, use <a href="https://www.w3.org/WAI/WCAG21/Techniques/aria/ARIA19">aria-live regions</a>.
  </li>
    </ul>
    <span id="examples" className="docs-b-Link--anchor"></span>
    <SprkHeading element="h2" additionalClasses="sprk-u-mbm sprk-u-Measure" variant="displayThree" mdxType="SprkHeading">
  Examples
    </SprkHeading>
    <p>{`Use error messaging to clarify any system errors and additional constraints to the user.`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Choose a T-shirt size.`}</em>{` (Example: Radio Input, Dropdown)`}</li>
      <li parentName="ul"><em parentName="li">{`Choose at least one of the options.`}</em>{` (Example: Checkbox)`}</li>
      <li parentName="ul"><em parentName="li">{`Enter your first name.`}</em>{` (Example: Text Input)`}</li>
      <li parentName="ul"><em parentName="li">{`Enter an amount that is less than your current balance of $19,800.`}</em>{` (Example: Monetary Input)`}</li>
      <li parentName="ul"><em parentName="li">{`Enter a date within the past 99 years.`}</em>{` (Example: data Input constraint)`}</li>
      <li parentName="ul"><em parentName="li">{`Enter your 9-digit Social Security Number.`}</em></li>
      <li parentName="ul"><em parentName="li">{`Create a password that meets the following criteria:`}</em><ul parentName="li">
          <li parentName="ul"><em parentName="li">{`It has at least 8 characters.`}</em></li>
          <li parentName="ul"><em parentName="li">{`Contains a number.`}</em></li>
          <li parentName="ul"><em parentName="li">{`Contains a special character.`}</em></li>
        </ul></li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`The guides are not all-inclusive in regard to accessibility considerations. Refer to the `}<a parentName="p" {...{
          "href": "https://www.w3.org/TR/WCAG21/"
        }}>{`Web Content Accessibility Guidelines (WCAG 2.1, Level AA)`}</a>{` for additional insights.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      